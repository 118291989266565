import React from 'react';
import TrashBin from "./components/Trashbin";

function App() {
  return (
      <div className="App">
        <TrashBin/>

      </div>
  );
}

export default App;
